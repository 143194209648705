import { Link, redirect } from '@remix-run/react';
import { captureException } from '@sentry/remix';
import { useEffect } from 'react';

import config from '../../src/config';

export async function loader() {
  if (
    import.meta.env.DEV ||
    (config.app.baseUrl.match('gadder.live') &&
      config.app.homeUrlOrPathname === '/')
  )
    return null;
  return redirect(config.app.homeUrlOrPathname);
}

export default function Component() {
  useEffect(() => {
    captureException(new Error('DEV_ONLY_CONTENT_VISIBLE_IN_PROD'));
  }, []);

  return (
    <main className='text-white w-screen h-screen flex flex-col justify-center items-center gap-4'>
      <p className='w-1/2'>
        This is a dev-only page preventing an infinite redirect in the event
        that the <code>HOME_URL_OR_PATHNAME</code> env var is not or incorrectly
        set. Current value: <code>{config.app.homeUrlOrPathname}</code>
      </p>

      <p className='w-1/2 text-center'>
        You probably want to{' '}
        <Link className='text-secondary' to='/login'>
          Login
        </Link>
        .
      </p>
    </main>
  );
}
